var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "citation" }, [
    _c(
      "div",
      { class: "citation__grid citation__grid--" + _vm.citation.layout },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasImage || _vm.hasVideo,
                expression: "hasImage || hasVideo",
              },
            ],
            staticClass: "citation__media",
          },
          [
            _vm.hasImage && !_vm.hasVideo
              ? _c("image-tag", {
                  attrs: {
                    image: _vm.citation.image,
                    definition: "personaCitation",
                  },
                })
              : _vm.hasVideo
              ? _c("video-embed", {
                  attrs: {
                    "media-file": _vm.citation.video.video.videoId,
                    poster: _vm.citation.image.url,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "citation__text",
            class: { "citation__text--addSign": _vm.citation.showCitationSign },
          },
          [
            _c("blockquote", [
              _vm.citation.showCitationSign
                ? _c("div", { staticClass: "citation__sign" }, [
                    _c("span", [_vm._v("„")]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                !_vm.citation.showCitationSign
                  ? _c("span", [_vm._v("»")])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.citation.quote) },
                }),
                _vm._v(" "),
                !_vm.citation.showCitationSign
                  ? _c("span", [_vm._v("«")])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.citation.author || _vm.citation.position
                ? _c("footer", [
                    _c("cite", [
                      _vm.citation.author
                        ? _c("strong", [_vm._v(_vm._s(_vm.citation.author))])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.citation.position
                        ? _c("span", [_vm._v(_vm._s(_vm.citation.position))])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }