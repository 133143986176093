















































import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import ImageTag from '../../../Component/Misc/Image/ImageTag/ImageTag.vue';
import VideoEmbed from '../../../Component/Misc/Video/VideoEmbed/VideoEmbed.vue';
import {Citation} from '../../../Interface/Citation';

export default {
    name: 'Citation',
    components: {ImageTag, VideoEmbed},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        citation(): Citation
        {
            return this.context.data.get('citation', []);
        },
        hasImage(): boolean
        {
            return !isEmpty(this.citation.image);
        },
        hasVideo(): boolean
        {
            return !isEmpty(this.citation.video);
        }
    }
};
